import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { withStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
// import StarIcon from "@material-ui/icons/Star";
import { headerScreenLabels } from "../../../common/common-types";
import locatoricon from "../../../assets/icons/more/locatoricon.png";
import mallmapicon from "../../../assets/icons/more/mallmap.svg";
import { List, ListItemText, ListItem, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { styles } from "./HeaderSubmitBillStyle";
import { getStorageItem } from "../../../common/localStorage";

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

class Header extends Component {
  state = {
    popoverAnchorEl: null,
  };

  handlePopoverClick = (event) => {
    // this.setState({
    //   popoverAnchorEl: event.currentTarget,
    // });
    const { history } = this.props;
    history.replace("/bill-upload-guidelines");
  };

  handlePopoverClose = () => {
    this.setState({
      popoverAnchorEl: null,
    });
  };

  pushToCart = () => {
    this.props.history.push("/myCart");
  };


  // New Function Added
  redirectBasedOnAuth = (history) => {
    if (localStorage?.user) {
      history.replace('/more');
    } else {
      history.replace('/login');
    }
  };

  headerContent = () => {
    const { screenLabel, classes, searchActive } = this.props;
    const isGuideline =
      getStorageItem("guidelinesFlag") && getStorageItem("guidelinesFlag") == 1;
    switch (screenLabel) {
      case headerScreenLabels.submitBill:
        const openPopover = Boolean(this.state.popoverAnchorEl);
        const id = openPopover ? "simple-popover" : undefined;
        const { popoverAnchorEl } = this.state;

        return (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            <IconButton
              onClick={() => {
                if (this.props.doneBtnStatus) {
                  return;
                } else {
                  this.props.history.replace("/dashboard");
                }
              }}
              className={classes.p0}
            >
              <span
                className={classes.backBtnIconCss + " " + classes.backBtn}
              />
            </IconButton>
            <Typography variant="h3" className={classes.mainHeader}>
              Submit Bill(s)
            </Typography>
            <IconButton
              onClick={this.handlePopoverClick}
              className={classes.p0}
            >
              <span
                className={classes.questionMarkIconCss + " " + classes.infobtn}
              />
            </IconButton>
            <Popover
              id={id}
              open={openPopover}
              anchorEl={popoverAnchorEl}
              onClose={this.handlePopoverClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Typography className={classes.typography}>
                <List>
                  <ListItem>
                    {/* <ListItemIcon>
                      <StarIcon />
                    </ListItemIcon> */}
                    <ListItemText primary="Submit your shopping bills, and get rewarded." />
                  </ListItem>
                </List>
              </Typography>
            </Popover>
          </Toolbar>
        );

      case headerScreenLabels.billSummary:
        return this.props.history.location.state.from &&
          this.props.history.location.state.from === "/transactions" ? (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            <IconButton
              onClick={() => {
                this.props.history.goBack();
              }}
              className={classes.p0}
            >
              <span
                className={classes.backBtnIconCss + " " + classes.backBtn}
              />
            </IconButton>
            <Typography variant="h3" className={classes.mainHeader}>
              Bill Details
            </Typography>
            <div />
          </Toolbar>
        ) : (
          <Toolbar
            disableGutters
            className={classes.toolbar_bill_summary}
            classes={{ regular: classes.regular }}
          >
            <Typography variant="h3" className={classes.mainHeader}>
              Bill(s) Summary
            </Typography>
          </Toolbar>
        );

      case headerScreenLabels.orderSummary:
        return this.props.history.location.state.from &&
          this.props.history.location.state.from === "/transactions" ? (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            <IconButton
              onClick={() => {
                this.props.history.goBack();
              }}
              className={classes.p0}
            >
              <span
                className={classes.backBtnIconCss + " " + classes.backBtn}
              />
            </IconButton>
            <Typography variant="h3" className={classes.mainHeader}>
              Order Details
            </Typography>
            <div />
          </Toolbar>
        ) : (
          <Toolbar
            disableGutters
            className={classes.toolbar_bill_summary}
            classes={{ regular: classes.regular }}
          >
            <Typography variant="h3" className={classes.mainHeader}>
              Order Summary
            </Typography>
          </Toolbar>
        );

      case headerScreenLabels.more:
        return (
          <Toolbar
            disableGutters
            className={classes.toolbar_bill_summary}
            classes={{ regular: classes.regular }}
          >
            <Typography variant="h3" className={classes.mainHeader}>
              More
            </Typography>
          </Toolbar>
        );
      case headerScreenLabels.aboutUs:
        return (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            <IconButton
              onClick={() => {
                this.redirectBasedOnAuth(this.props?.history)
              }}
              className={classes.p0}
            >
              <span
                className={classes.backBtnIconCss + " " + classes.backBtn}
              />
            </IconButton>
            <Typography variant="h3" className={classes.mainHeader}>
              About Us
            </Typography>
            <div></div>
          </Toolbar>
        );

      case headerScreenLabels.contactUs:
        return (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            <IconButton
              onClick={() => {
                this.redirectBasedOnAuth(this.props?.history)
              }}
              className={classes.p0}
            >
              <span
                className={classes.backBtnIconCss + " " + classes.backBtn}
              />
            </IconButton>
            <Typography variant="h3" className={classes.mainHeader}>
              Contact Us
            </Typography>
            <div></div>
          </Toolbar>
        );

      case headerScreenLabels.storeLocator:
        return (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            <IconButton
              onClick={() => {
                this.redirectBasedOnAuth(this.props?.history)
              }}
              className={classes.p0}
            >
              <span
                className={classes.backBtnIconCss + " " + classes.backBtn}
              />
            </IconButton>
            <Typography variant="h3" className={classes.mainHeader}>
              {this.props.isBrand
                ? "Store Profile"
                : "Store Locator / Mall Directory"}
            </Typography>
            {
              <IconButton
                onClick={() => !this.props.isBrand && this.props.showMap(true)}
                className={classes.p0}
                style={{ borderRadius: 1 }}
              >
                {!this.props.isBrand && (
                  <img src={locatoricon} className={classes.locicon} alt="sl" />
                )}
              </IconButton>
            }
          </Toolbar>
        );

      case headerScreenLabels.showmall:
        return (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            <IconButton
              onClick={() => {
                this.props.back();
              }}
              className={classes.p0}
            >
              <span
                className={classes.backBtnIconCss + " " + classes.backBtn}
              />
            </IconButton>
            <Typography variant="h3" className={classes.mainHeader}>
              Store Locator / Mall Directory
            </Typography>
            <IconButton
              onClick={() => this.props.showMap(false)}
              className={classes.p0}
              style={{ borderRadius: 1 }}
            >
              <img src={mallmapicon} className={classes.locicon} alt="sl" />
            </IconButton>
          </Toolbar>
        );

      case headerScreenLabels.termsOfUse:
        return (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            <IconButton
              onClick={() => {
                this.redirectBasedOnAuth(this.props?.history)
              }}
              className={classes.p0}
            >
              <span
                className={classes.backBtnIconCss + " " + classes.backBtn}
              />
            </IconButton>
            <Typography variant="h3" className={classes.mainHeader}>
              Terms Of Use
            </Typography>
            <div></div>
          </Toolbar>
        );

      case headerScreenLabels.howitworks:
        return (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            <IconButton
              onClick={() => {
                this.redirectBasedOnAuth(this.props?.history)
              }}
              className={classes.p0}
            >
              <span
                className={classes.backBtnIconCss + " " + classes.backBtn}
              />
            </IconButton>
            <Typography variant="h3" className={classes.mainHeader}>
              How Infiniti World works?
            </Typography>
            <div></div>
          </Toolbar>
        );
      case headerScreenLabels.faqs:
        return (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            <IconButton
              onClick={() => {
                this.redirectBasedOnAuth(this.props?.history)
              }}
              className={classes.p0}
            >
              <span
                className={classes.backBtnIconCss + " " + classes.backBtn}
              />
            </IconButton>
            <Typography variant="h3" className={classes.mainHeader}>
              FAQs
            </Typography>
            <div></div>
          </Toolbar>
        );

      case headerScreenLabels.feedback:
        return (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            <IconButton
              onClick={() => {
                this.redirectBasedOnAuth(this.props?.history)
              }}
              className={classes.p0}
            >
              <span
                className={classes.backBtnIconCss + " " + classes.backBtn}
              />
            </IconButton>
            <Typography variant="h3" className={classes.mainHeader}>
              Feedback
            </Typography>
            <div></div>
          </Toolbar>
        );

      case headerScreenLabels.myprofile:
        return (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            <IconButton
              onClick={() => {
                this.props.history.push("/dashboard");
              }}
              className={classes.p0}
            >
              <span
                className={classes.backBtnIconCss + " " + classes.backBtn}
              />
            </IconButton>
            <Typography variant="h3" className={classes.mainHeader}>
              My Profile
            </Typography>
            <div />
          </Toolbar>
        );

      case headerScreenLabels.editprofile:
        return (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            <IconButton
              onClick={() => {
                this.props.history.push("/my-profile");
              }}
              className={classes.p0}
            >
              <span
                className={classes.backBtnIconCss + " " + classes.backBtn}
              />
            </IconButton>
            <Typography variant="h3" className={classes.mainHeader}>
              Edit My Profile
            </Typography>
            <div />
          </Toolbar>
        );
      case headerScreenLabels.offers:
        const { heading } = this.props;
        return (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            {/* <Typography variant="h3" className={classes.mainHeader}> */}
            {!this.props.backBtn ? (
              <div className={classes.emptyDiv}>
                {/* empty div to keep the css effects as it is */}
              </div>
            ) : (
              <IconButton
                onClick={(e) => {
                  if (this.props.backBtnStatus) {
                    this.props.backBtnClickHandler(e);
                  } else {
                    this.props.history.location.key
                      ? this.props.history.goBack()
                      : this.props.history.replace("/");
                  }
                }}
                className={classes.p0}
              >
                <span
                  className={
                    classes.backBtnOfferIconCss + " " + classes.backBtn
                  }
                ></span>
              </IconButton>
            )}
            <Typography
              variant="h3"
              className={classnames(classes.mainHeader, {
                [classes.offerHeader]: !this.props.backBtn,
              })}
            >
              {heading}
            </Typography>
            {!this.props.backBtn ? (
              <div className={classes.icon_panel}>
                <div
                  className={classes.searchIconCss + " " + classes.searchIcon}
                  onClick={this.props.toggleSearchBox}
                />
                <IconButton
                  className={classes.p0}
                  onClick={() => this.pushToCart()}
                >
                  <span
                    className={classes.cartIconCss + " " + classes.cartbtn}
                  ></span>
                  {this.props.cartItemCount &&
                  this.props.cartItemCount.count > 0 ? (
                    <Grid className={classes.countClass}>
                      {this.props.cartItemCount.count}
                    </Grid>
                  ) : null}
                </IconButton>
              </div>
            ) : (
              <div></div>
            )}
            {/* </Typography> */}
          </Toolbar>
        );

      case headerScreenLabels.billUploadGuidelines:
        return (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            {isGuideline ? (
              <IconButton
                onClick={() => {
                  this.props.history.replace("/submit-bill");
                }}
                className={classes.p0}
              >
                <span
                  className={classes.backBtnIconCss + " " + classes.backBtn}
                />
              </IconButton>
            ) : (
              <div></div>
            )}
            <Typography variant="h3" className={classes.mainHeader}>
              Bill Upload Guidelines
            </Typography>
            <div />
          </Toolbar>
        );

      case headerScreenLabels.notifications:
        return (
          <Toolbar
            disableGutters
            className={classes.toolbar}
            classes={{ regular: classes.regular }}
          >
            <IconButton
              onClick={() =>
                this.props.history.location.key
                  ? this.props.history.goBack()
                  : this.props.history.replace("/")
              }
              className={classes.p0}
            >
              <span
                className={classes.backBtnIconCss + " " + classes.backBtn}
              />
            </IconButton>
            <Typography variant="h3" className={classes.mainHeader}>
              Notifications
            </Typography>
            <div />
          </Toolbar>
        );

      default:
        break;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <ElevationScroll>
          <AppBar position="fixed" className={classes.appbar}>
            {this.headerContent()}
          </AppBar>
        </ElevationScroll>
        <div className={classes.toolbarMargin} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    doneBtnStatus: state.reducerSubmitBill.doneBtnStatus,
  };
};

export default connect(mapStateToProps)(
  withRouter(withStyles(styles, { withTheme: true })(Header))
);
