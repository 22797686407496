import React, { Component } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import OrderSummaryCard from "../../../../components/OrderSummaryCard/OrderSummaryCard";
import { getStorageItem } from "../../../../common/localStorage";
import moment from "moment";

const styles = (theme) => {
  return {
    pageContainer: {
      padding: "30px",
      [theme.breakpoints.down("641")]: {
        padding: "28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px",
      },
      backgroundColor: theme.palette.common.globalBackground,
      height: "100%",
      display: "block",
      textAlign: "center",
      overflowY: "auto",
    },
    main_page_item: {
      width: "100%",
      display: "block",
    },
    container_root: {
      height: "100%",
      maxWidth: "660px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      margin: "0 auto",
    },
    topTitle: {
      margin: "0",
      padding: "0px",
      display: "block",
      width: "100%",
      textAlign: " center",
      "& h1": {
        margin: "0px 0px 5px 0px",
        padding: "0px",
        display: "block",
        fontWeight: "bold",
        color: theme.palette.orderSummary.title,
        fontSize: "22px",
        [theme.breakpoints.down("641")]: {
          fontSize: "20px",
        },
        [theme.breakpoints.down("481")]: {
          fontSize: "18px",
        },
        [theme.breakpoints.down("421")]: {
          fontSize: "17.5px",
        },
        [theme.breakpoints.down("401")]: {
          fontSize: "17px",
        },
        [theme.breakpoints.down("381")]: {
          fontSize: "16.5px",
        },
        [theme.breakpoints.down("361")]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down("321")]: {
          fontSize: "14px",
        },
      },
      "& h2": {
        margin: "0px 0px 7px 0px",
        padding: "0px",
        display: "block",
        fontWeight: "bold",
        color: theme.palette.typography.texts.color,
        fontSize: "20px",
        [theme.breakpoints.down("641")]: {
          fontSize: "18px",
        },
        [theme.breakpoints.down("481")]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down("421")]: {
          fontSize: "15.5px",
        },
        [theme.breakpoints.down("401")]: {
          fontSize: "15px",
        },
        [theme.breakpoints.down("381")]: {
          fontSize: "14.5px",
        },
        [theme.breakpoints.down("361")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("321")]: {
          fontSize: "12px",
        },
      },
    },
    txtMore: {
      margin: "7px 0px 13px 0px",
      padding: " 0px",
      display: " block",
      fontWeight: "400",
      color: theme.palette.typography.texts.sellingPriceColor,
      fontSize: "18px",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    totalReedem: {
      padding: theme.spacing(1),
      display: "block",
      width: "100%",
      borderRadius: "6px",
      overflow: "hidden",
      boxShadow: "0px 0px 10px #e9e9e9",
      position: "relative",
      backgroundColor: theme.palette.cards.backgroundColor,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1),
      },
    },
    points_calculation: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    common_font: {
      fontSize: "18px",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    label_points: {
      color: theme.palette.typography.texts.color,
    },
    totalRedeemPoints: {
      fontWeight: "bold",
      color: theme.palette.typography.texts.color,
    },
    remainingPoints: {
      fontWeight: "bold",
      color: theme.palette.orderSummary.title,
    },
    doneBtn: {
      ...theme.palette.typography.doneButtons,
      height: "92px",
      fontSize: "18px",
      [theme.breakpoints.down("641")]: {
        height: "86px",
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        height: "54px",
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        height: "46px",
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        height: "46px",
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        height: "40px",
        fontSize: "10px",
      },
    },
  };
};
class OrderSummary extends Component {
  getItemPrice = (specialPrice, startDate, endDate, sellingPrice) => {
    const currentDate = moment().format("YYYY-MM-DD");
    if (specialPrice === "0.0000") {
      return parseFloat(sellingPrice) % 1 !== 0
        ? parseFloat(sellingPrice).toFixed(2)
        : Math.floor(sellingPrice);
    } else if (endDate && endDate === currentDate) {
      return parseFloat(specialPrice) % 1 !== 0
        ? parseFloat(specialPrice).toFixed(2)
        : Math.floor(specialPrice);
    } else if (startDate && startDate === currentDate) {
      return parseFloat(specialPrice) % 1 !== 0
        ? parseFloat(specialPrice).toFixed(2)
        : Math.floor(specialPrice);
    } else if (moment(currentDate).isBetween(startDate, endDate)) {
      return parseFloat(specialPrice) % 1 !== 0
        ? parseFloat(specialPrice).toFixed(2)
        : Math.floor(specialPrice);
    } else {
      return parseFloat(sellingPrice) % 1 !== 0
        ? parseFloat(sellingPrice).toFixed(2)
        : Math.floor(sellingPrice);
    }
  };

  render() {
    const { classes } = this.props;
    const {
      handleRedeemBtn,
      orderId,
      orderDetails,
      totalPointsRedeem,
      currentPointBalance,
      orderDate,
    } = this.props;

    return (
      <Grid className={classes.pageContainer}>
        <Grid className={classes.main_page_item}>
          <Grid className={classes.container_root}>
            <div className={classes.topTitle}>
              {this.props.history.location.state.from &&
              this.props.history.location.state.from ===
                "/transactions" ? null : (
                <h1>Order Placed Successfully!</h1>
              )}

              <h2 className={classes.common_font}>
                Order Reference #: {orderId}
              </h2>
              {this.props.history.location.state.from &&
              this.props.history.location.state.from === "/transactions" ? (
                <Typography className={classes.txtMore}>
                  Date: {orderDate}
                </Typography>
              ) : (
                <Typography className={classes.txtMore}>
                  For more details please check{" "}
                  <span style={{ color: "#A03232" }}>'Transactions'</span>
                </Typography>
              )}
            </div>
            {orderDetails.length > 0 &&
              orderDetails.map((item, index) => {
                return (
                  <OrderSummaryCard
                    key={index + "-" + item.brand_name}
                    brandName={item.brand_name ? item.brand_name : ""}
                    imgSrc={item.image_name}
                    voucherValue={
                      item.voucher_value
                        ? parseFloat(item.voucher_value) % 1 !== 0
                          ? parseFloat(item.voucher_value).toFixed(2)
                          : parseFloat(item.voucher_value)
                        : ""
                    }
                    subProductName={
                      item.sub_product_name ? item.sub_product_name : ""
                    }
                    e_vouchers={item.e_vouchers && item.e_vouchers}
                    // expiry_date={item.expiry_date}
                    itemQuantity={item.item_quantity}
                    price={this.getItemPrice(
                      item.special_price_points_value,
                      item.special_price_start_date,
                      item.special_price_end_date,
                      item.selling_price_points_value
                    )}
                    expiry_date={item.expiry_date}

                    showStatus={
                      this.props.history.location.state.from &&
                      this.props.history.location.state.from === "/transactions"
                    }
                    status={item.order_status_text}
                    location_name={item.location_name}
                  />
                );
              })}
            {this.props.history.location.state.from &&
            this.props.history.location.state.from ===
              "/transactions" ? null : (
              <Grid item className={classes.totalReedem}>
                <Typography className={classes.points_calculation}>
                  <Typography
                    component="span"
                    className={classes.label_points + " " + classes.common_font}
                  >
                    Total Redeemed
                  </Typography>
                  <Typography
                    component="span"
                    className={
                      classes.common_font + " " + classes.totalRedeemPoints
                    }
                  >
                    {totalPointsRedeem +
                      ` ${
                        getStorageItem("pointShortName")
                          ? getStorageItem("pointShortName")
                          : "P"
                      }`}
                  </Typography>
                </Typography>
                <Typography className={classes.points_calculation}>
                  <Typography
                    component="span"
                    className={classes.label_points + " " + classes.common_font}
                  >
                    Your New Balance
                  </Typography>
                  <Typography
                    component="span"
                    className={
                      classes.common_font + " " + classes.remainingPoints
                    }
                  >
                    {currentPointBalance +
                      ` ${
                        getStorageItem("pointShortName")
                          ? getStorageItem("pointShortName")
                          : "P"
                      }`}
                  </Typography>
                </Typography>
              </Grid>
            )}

            {this.props.history.location.state.from &&
            this.props.history.location.state.from ===
              "/transactions" ? null : (
              <Grid style={{ width: "100%" }}>
                <Button
                  className={classes.doneBtn}
                  disableRipple
                  color="primary"
                  variant="contained"
                  onClick={handleRedeemBtn}
                >
                  VIEW TRANSACTIONS
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(OrderSummary)
);
